import './bootstrap-common';

import 'bootstrap-datepicker/dist/js/bootstrap-datepicker';
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min';
import 'jquery.scrollto/jquery.scrollTo.min';
import 'jquery-placeholder/jquery.placeholder';
import initAllUtils from '@kdose/symfony-bootstrap/js/utils';
import '@kdose/symfony-bootstrap/js/jquery-utils/jquery.stringtodate';
import '@kdose/symfony-bootstrap/js/jquery-utils/jquery.bootstrap-show-hide-toggle.js';
import './utils/bootstrap-init';
import { initChoices } from './utils/initChoices';

initAllUtils();
initChoices();
