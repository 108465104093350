import $ from 'jquery';

$('[rel=popover]').popover({
  container: 'body',
});
$('[title]:not([rel=popover])').tooltip();

$('.datepicker').datepicker({
  startView: new Date(),
  language: 'de',
  autoclose: true,
  forceParse: false,
  maxViewMode: 2
});

// wandelt z.B. 14011996 in 14.01.1996 um
const $stringToDate = $('.stringtodate');
if ($stringToDate.length > 0) {
  $stringToDate.stringToDate();
}

/**
 * Tooltips für Verkleinerung von Button-Text
 */
if (window.matchMedia) {
  const mq = window.matchMedia('(max-width: 1199px)');
  mq.addListener(buttonTextResponsive);
  buttonTextResponsive(mq);
}

function buttonTextResponsive(mq) {
  if (mq.matches) {
    $('.button-text').each(function () {
      iterateButton($(this), true);
    });

  } else {
    $('.button-text').each(function () {
      iterateButton($(this), false);
    });

  }
}

function iterateButton(item, matched) {
  const text = item.text();
  let $btn = item.parents('.btn');

  if ($btn.length < 1) {
    $btn = item.parents('a');
  }

  if (matched) {
    if (typeof $btn.attr('title') === 'undefined') {
      $btn.attr('title', text);
      $btn.tooltip();
    }
  } else {
    if ($btn.attr('data-original-title') === text) {
      $btn.removeAttr('title');
      $btn.removeAttr('data-original-title');
      $btn.tooltip('destroy');
    }
  }
}

// Vorgangs-Schnellsuche mit Aktenzeichen vorbelegen
$('.issueQuickSearch').on('mouseup', function () {
  if (typeof $(this).attr('data-value') !== 'undefined' && $(this).val() === '') {
    $(this).attr('placeholder', $(this).attr('data-value'));
  }

  // Suchfeld verbreitern
  $(this).parents('.form-group').css({
    width: '200px'
  });

  // Menüpunkte ausblenden, um mehr Platz zu haben
  $('.hide-on-search').hide();
}).on('blur', function () {
  if (typeof $(this).attr('data-value') !== 'undefined') {
    $(this).attr('placeholder', 'Suche');
  }

  // Suchfeld wieder schmaler machen
  $(this).parents('.form-group').css({
    width: '120px'
  });

  // Menüpunkte wieder einblenden
  setTimeout(function () {
    $('.hide-on-search').show();
  }, 700);

});
